import React from 'react'

export default () => {
  return (
    <ul style={{ padding: '20px' }}>
      <li>
        Tadalafil Prescribing Information,{' '}
        <a href="https://www.accessdata.fda.gov/drugsatfda_docs/label/2011/021368s20s21lbl.pdf">click here</a>
      </li>
      <li>
        Vardenafil Prescribing Information,{' '}
        <a href="https://www.accessdata.fda.gov/drugsatfda_docs/label/2007/021400s010lbl.pdf">click here</a>
      </li>
    </ul>
  )
}
